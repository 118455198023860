// import { usePayPalScriptReducer } from "@paypal/react-paypal-js";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import heart from "../../assets/images/shapes/Green-ball.png";
import { Link } from "@gatsbyjs/reach-router";

const PeCurriculum = ({ showbutton, top }) => {
  return (
    <section className={`${top && "pt-120"} pb-120`}>
      <Container>
        <Row className="pb-40">
          <Col lg={10}>
            <div className="donate-options__content">
              <div className="block-title">
                <p>
                  <img src={heart} width="15" alt="" />
                  Education
                </p>
                <h3>PE Curriculum</h3>
              </div>
              <p>
                Made You Love Tennis Foundation partners with local schools to
                introduce tennis to students for physical education from Pre-K
                to 6th grade. The program is typically held in a gymnasium or
                cafeteria, and MYLT provides tennis equipment and
                low-compression balls. The PE Curriculum combines fun tennis
                activities with lessons in sportsmanship, leadership, teamwork,
                and essential concepts and principles.
              </p>
            </div>
          </Col>
        </Row>
        {showbutton && (
          <Link className="thm-btn dynamic-radius" to="/programs/pe-curriculum">
            Learn More
          </Link>
        )}
      </Container>
    </section>
  );
};

export default PeCurriculum;
