import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/layout";
import HeaderTwo from "../../components/header/header-two";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import PageHeader from "../../components/page-header";
import AakdProgram from "../../components/programs/aakd";
import PeCurriculum from "../../components/programs/pecurriculum";
import AfterSchool from "../../components/programs/afterschool";
import Services from "../../components/services";

const Programs = () => (
  <Layout pageTitle="Made You Love Tennis Foundation | Programs">
    <HeaderTwo />
    <div className="about-page">
      <StickyHeader extraClassName="stricky-header-two" />
      <PageHeader title="Programs" crumbTitle="Programs" />
      <Container className="about-headertext">
        <Row>
          Made You Love Tennis provides diverse programs, including curriculum,
          after-school tennis, fundraisers, and community events. Our programs
          are designed to empower participants to realize their full potential
          and contribute meaningfully to society.
        </Row>
      </Container>
      <AakdProgram showbutton={true} top={true} />
      <PeCurriculum showbutton={false} />
      <AfterSchool showbutton={false} />
      <Services />
    </div>
    <Footer />
  </Layout>
);

export default Programs;
