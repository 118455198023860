// import { usePayPalScriptReducer } from "@paypal/react-paypal-js";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import heart from "../../assets/images/shapes/Green-ball.png";
import { Link } from "@gatsbyjs/reach-router";

const AfterSchool = ({ showbutton, top }) => {
  return (
    <section className={`${top && "pt-120"} pb-120`}>
      <Container>
        <Row className="pb-40">
          <Col lg={10}>
            <div className="donate-options__content">
              <div className="block-title">
                <p>
                  <img src={heart} width="15" alt="" />
                  Extra Curriculars
                </p>
                <h3>After School</h3>
              </div>
              <p>
                Made You Love Tennis serves students aged 4-17 in various
                locations, including gymnasiums, parks, community centers, hoa
                subdivisions, and tennis clubs. Our dedicated staff provides
                tutoring, mentoring, prevention, and intervention programs to
                guide students on the right path.
              </p>
            </div>
          </Col>
        </Row>
        {showbutton && (
          <Link className="thm-btn dynamic-radius" to="/programs/after-school">
            Learn More
          </Link>
        )}
      </Container>
    </section>
  );
};

export default AfterSchool;
